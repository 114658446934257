import { MdClose } from "react-icons/md"
import { useState, useEffect } from "react"
import "./css/CarSpecifications.css"
import { BiChevronDown, BiSearch } from "react-icons/bi"
import DropDown from "../components/DropDown"
import { useNavigate } from "react-router-dom"
import api from "../config/backend"
import axios from "axios"

const CarSpecifications = ({setPopup, vehicleid}) => {
    const [vehicleMake, setVehicleMake] = useState(undefined)
    const [vehicleManufacturingYear, setVehicleManufacturingYear] = useState(undefined)
    const [vehicleModel, setVehicleModel] = useState(undefined)
    const [vehicleData, setVehicleData] = useState({ 
        make: undefined,
        manufacteryear: undefined,
        model: undefined
    })
    
    // const [vehicleVariant, setVehicleVariant] = useState("")
    // const [manufacturingYear, setManufacturingYear] = useState("")

    const navigate = useNavigate()

    const vehiclemake = async () => {
        const getapi = api("caps", "getapi")
        const response = await axios.get(getapi)

        setVehicleData(prev => {
            const copy = {...prev}
            copy.make = response.data.map(item => item.make)
            return copy
        })
    }

    const manufacture = async (vehiclemake) => {
        const manugetapi = api("caps", "manugetapi")
        const response = await axios.post(manugetapi, {
            make: vehiclemake
        })

        setVehicleData(prev => {
            const copy = {...prev}
            copy.manufacteryear = response.data.map(item => item.manufacteryear)
            return copy
        })
    }

    useEffect(() => {
        if (vehicleMake === undefined) {
            vehiclemake()
        }
        manufacture(vehicleMake)
    }, [vehicleMake])

    useEffect(() => {
        const modelgetapi = async () => {
            const modelgetapi = api("caps", "modelgetapi")
            console.log(vehicleManufacturingYear)
            const response = await axios.post(modelgetapi, {
                manufacteryear: vehicleManufacturingYear
            })
    
            setVehicleData(prev => {
                const copy = {...prev}
                copy.model = response.data.map(item => item.model)
                return copy
            })
        }
        modelgetapi()
    }, [vehicleManufacturingYear])

    const placeholderCorrect = (vmodel) => {
        return vmodel.slice(0, vmodel.indexOf("["))
    }

    return (
        <div className="_5zfr">
            <div className="_4hou">
                <MdClose onClick={() => setPopup({active: false, location: undefined})} />
                <div className="vehicle-filter">
                    <div className="container">
                        <div>
                            {vehicleMake ? <span className="vehicle-make">Make ({vehicleMake})</span> : <span className="vehicle-make">Make (Maruti)</span>}
                            <BiChevronDown />
                            <DropDown placeholder="Make (Maruti)" value="Maruti" items={vehicleData.make} setValue={setVehicleMake}/>
                        </div>
                        <div className="mileage-input">
                            <span>Manufacturing <span className="_0kgg">({vehicleManufacturingYear ? placeholderCorrect(vehicleManufacturingYear) : "1000"})</span></span>
                            <BiChevronDown />
                            <DropDown placeholder="Manufacturing (Baleno)" value="Baleno" items={vehicleData.manufacteryear} setValue={setVehicleManufacturingYear} />
                        </div>
                        <div>
                            {vehicleModel ? <span>Model <span className="_0kgg">({vehicleModel})</span></span> : <span>Model <span className="_0kgg">(Delta MT)</span></span>}
                            <BiChevronDown />
                            <DropDown placeholder="Model (Delta MT)" value="Delta MT" items={vehicleData.model} setValue={setVehicleModel} />
                        </div>
                        {/* <div>
                            <span>Select</span>
                            <BiChevronDown />
                            <DropDown placeholder="Variant (Petrol)"  />
                        </div> */}
                        <div 
                            className="search-button"
                            onClick={() => navigate(
                                "/vehicle-information/" + vehicleid, 
                                {
                                    state: { vehicleMake, vehicleModel, vehicleManufacturingYear  }
                                }
                            )}
                        >
                            <BiSearch />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarSpecifications

