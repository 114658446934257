import "./css/MarketValuePopup.css"
import { MdClose } from "react-icons/md"
import { BiChevronDown, BiSearch } from "react-icons/bi"
import DropDown from "../components/DropDown"

const MarketValuePopup = ({setPopup}) => {
    return (
        <div className="_3kwf">
            <div className="_3rbo">
                <MdClose onClick={() => setPopup({active: false, location: undefined})} />
                <div className="vehicle-filter">
                    <div className="container">
                        <div>
                            <span>Make</span>
                            <BiChevronDown />
                            <DropDown />
                        </div>
                        <div>
                            <span>Model</span>
                            <BiChevronDown />
                            <DropDown />
                        </div>
                        <div>
                            <span>Variant</span>
                            <BiChevronDown />
                            <DropDown />
                        </div>
                        <div>
                            Manufacturing Year
                        </div>
                        <div className="mileage-input">
                            Mileage (in Kms)
                        </div>
                        <div className="search-button">
                            <BiSearch />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarketValuePopup
