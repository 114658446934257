import React, { memo } from 'react'
import './style.css';
import img1 from '../images/5.png';
import img2 from '../images/6.png';
import img3 from '../images/7.png';
import img4 from '../images/8.png';
import { ImCheckmark } from 'react-icons/im';
import { ImMinus } from 'react-icons/im';
import { ImCross } from 'react-icons/im';
import logo from '../images/logo.png';
function First_section_last({vehicledata, totalloss}) {
  return (
    <div className="_0ybt first_section_last_top_container">
      <div className='first_section_last_sub'>
        <div className='motospy-text motopsy-text2'>
          {/* <h1>Motopsy</h1>
          <p>vehicle history report</p> */}
          <img src={logo} width="400px" height="90px" />

        </div>
        <div className="status-card one data" style={{ marginBottom: "56px" }}>
          <div className='image-container img-con-one' style={{ height: "133px" }}>
            <img src={img1} className="img1" />
            <span className='icon'>
				<ImCheckmark style={{ color: "white", fontSize: "30px" }} />
			</span>
          </div>
          <h1>Stolen Status<br />
            Checked </h1>
          <p> NOC attached
            for this vehicle
            being clean ,
            issued by NCRB</p>
        </div>
        <div className='one data'>
          <div className='image-container img-con-two'><img src={img2} className="img1" />
            <span 
              className={vehicledata && vehicledata.blacklistStatus ? "icon blacklist-icon" : "icon"}
            >
              {vehicledata && vehicledata.blacklistStatus ? <ImCross /> : <ImCheckmark /> }
            </span>
          </div>
          <h1>Blacklist Status
            Checked</h1>
          <p>NOC attached
            for this vehicle
            being clean , provided ny NCRB</p>
        </div>
        <div className='one data'>
          <div className='image-container img-con-three'><img src={img3} className="img1" />
            <span 
                className={vehicledata && vehicledata.rcFinancer == "" ? "icon" : "icon blacklist-icon"}
            >
              {vehicledata && vehicledata.rcFinancer == "" ? <ImCheckmark /> : <ImCross /> }
            </span>
          </div>
          <h1>Financial Status Checked </h1>
          <p>Information not available, in person/offline verification needed with respective RTO</p>
        </div>
        <div className='one data'>
          <div className='image-container img-con-four'><img src={img4} className="img1" />
            <span className='icon minus-icon' style={{ backgroundColor: "orange" }}>
              <div>-</div>
            </span>
          </div>
          <h1>Odometer Status

            Checked </h1>
          <p>Information available is not
              sufficient to conclude,
              please have vehicle
              physically examined</p>
        </div>
        {/* style={{marginTop:"85px"}} */}
        {totalloss == "false" ? (
          <div className='one data data-last' >
            <div className='image-container img-con-five' style={{ height: "120px" }}><img src={img1} className="img1" />
            <span className='icon minus-icon' style={{ backgroundColor: "orange" }}>
                <div>-</div>
              </span>
            </div>
            <h1>Vehicle Registered Checked</h1>
            <p>Information available is not
                sufficient to conclude,
                please have vehicle
                physically examined</p>
          </div>) : (
            <div className='one data data-last' >
              <div className='image-container img-con-five' style={{ height: "120px" }}><img src={img1} className="img1" />
              <span className='icon blacklist-icon' style={{ backgroundColor: "red" }}>
                <ImCross />
              </span>
              </div>
              <h1>Vehicle Registered Checked</h1>
              <p>ALERT! Vehicle reported Blacklist, seek details with respective RTO</p>
            </div>
          )}
      </div>
    </div>
  )
}

export default memo(First_section_last)
