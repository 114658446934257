import React, { useEffect, useState,memo } from 'react';
import './style.css';
import logo from '../images/logo.png';
import { BounceLoader, CircleLoader, ClipLoader, DotLoader } from "react-spinners";



function FirstSection_subMenu({setgetdata, vehicledata, getimage}) {
  let carImage = undefined
  const imageXml = new DOMParser().parseFromString(getimage, "text/xml")
  const stringElement = imageXml.getElementsByTagName("string")[0]

  if (stringElement) {
    carImage = stringElement.innerHTML
  }

// /?id=UEIxMUNYNTY0Nw==

  

  // if(id==null)
  // {
  //   window.location.href="http://motopsy.com";
  // }
  // useEffect(()=>{
  //   printSelectedData(document.getElementById('register-data').innerText);
  // })

  return (
    <div id="details" className='_6zaz first-section-top-div'>
      <div className='contain-both' id="register-data">
        <div className='first-section-mid-div'>
          <div className='first-section-mid-lorem-div odd left'><p>Registration No</p></div>
          <div className='first-section-mid-lorem-div eve left'><p>Make</p></div>
          <div className='first-section-mid-lorem-div odd left'><p>Model and Trim</p></div>
          <div className='first-section-mid-lorem-div eve left'><p>Color</p></div>
          <div className='first-section-mid-lorem-div odd left'><p>Manufacturing Year</p></div>
          <div className='first-section-mid-lorem-div eve left'><p>Registration Month & Year</p></div>
          <div className='first-section-mid-lorem-div odd left'><p>Fuel Type</p></div>
          <div className='first-section-mid-lorem-div eve left'><p>Ownership S.no</p></div>
          <div className='first-section-mid-lorem-div odd left'><p>Fitness/Registration Upto</p></div>
          <div className='first-section-mid-lorem-div eve left'><p>Vehicle Age</p></div>
          <div className='first-section-mid-lorem-div odd left'><p>Engine No</p></div>
          <div className='first-section-mid-lorem-div eve left'><p>Chasis No</p></div>
        </div>
        <div className='first-section-mid-div'>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.regNo}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.vehicleManufacturerName}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.model}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.vehicleColour}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.vehicleManufacturingMonthYear}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.regDate}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.type}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.ownerCount}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.vehicleInsuranceUpto}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.rcExpiryDate}</p></div>
          <div className='first-section-mid-lorem-div right' ><p>{vehicledata.engine}</p></div>
          <div className='first-section-mid-lorem-div right'><p>{vehicledata.chassis}</p></div>

        </div>
      </div>
      <div className="vehicle-details first-section-right-div">
        <div className='car-div'>
          <div className='first-section-right-div-data'>
            {/* <p className='p1'>MOTOPSY</p>
            <p className='p2'>Vehicle History Report</p> */}
            <img src={logo} height="60px" width="200px"/>
            {vehicledata ? (
              <p>
                "{vehicledata.vehicleManufacturerName}", {vehicledata.model} {vehicledata.vehicleManufacturingMonthYear && vehicledata.vehicleManufacturingMonthYear.split("/")[1]}
              </p>
            ) : undefined}
          </div>
          <div className="car-image first-section-right-div-car">
            {carImage ? <img src={carImage} alt="Car image" /> : <BounceLoader color="#a5afd9"/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(FirstSection_subMenu)
