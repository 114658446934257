import "./css/Header.css"
import { useState } from "react"
import Logo from "../images/logo.png"
import CarSpecifications from "./CarSpecifications"
import MarketValuePopup from "./MarketValuePopup"
import Mobile_menu from "../Mobile_menu"
import { useNavigate } from "react-router-dom"

const Header = ({ vehicleid }) => {
    const [popup, setPopup] = useState({active: false, location: undefined})

    const navigate = useNavigate()

    const today = new Date()

    const options = {day: 'numeric', month: 'short', year: 'numeric'}

    const date = today.toLocaleDateString("en-US", options)
    const time = today.toLocaleTimeString("en-US", {hour: 'numeric', minute: 'numeric', hour12: true})
    
    const Popup = () => {
		if (popup.active === false) {
			return undefined
		}
		if (popup.location === "specifications") {
			return <CarSpecifications setPopup={setPopup} vehicleid={vehicleid} />
		}
		if (popup.location === "market-value") {
			return <MarketValuePopup setPopup={setPopup} />
		}
	}

    const scrollPage = (location) => {
        navigate("/report/" + vehicleid, {
            state: { location }
        })
	}
    
    return (
        <header className="_4gjc">
            <Popup />
            <Mobile_menu />
            <div className="navigation">
                <div className="logo" onClick={() => navigate("/")}>
                    <img src={Logo} alt="" />
                </div>
                <div className="routes">
                    <div onClick={() => scrollPage(440)}>Vehicle Details</div>
                    <div onClick={() => scrollPage(7475)}>Legal Check</div>
                    <div>No Crime Record NOC</div>
                    <div onClick={() => scrollPage(1750)}>Total Loss/Written Off Check</div>
                    <div onClick={() => setPopup({active: true, location: "specifications"})}>
                        Car Specs & Features
                    </div>
                    <div onClick={() => setPopup({active: true, location: "market-value"})}>
                        Market Value Check
                    </div>
                    <div>How it works</div>
                </div>
            </div>
            <div className="report">
                <div className="report-id">
                    <span>Report ID</span>
                    <span>
                        <strong>5266958</strong>
                    </span>
                </div>
                <div className="report-date">
                    <span>Generated on:</span>
                    <span>{date} | {time}</span>
                </div>
            </div>
        </header>
    )
}

export default Header

