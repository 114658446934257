
import './App.css';

import img1 from '../src/images/1.png';
import img2 from '../src/images/2.png';
import img3 from '../src/images/3.png';
import img4 from '../src/images/4.png';
import Mobile_menu from './Mobile_menu';
import FirstSection_subMenu from './FirstSection_subMenu';
import First_section_last from './First_section_last';
import { AiFillPrinter } from 'react-icons/ai';
import { useState, useEffect } from 'react';
import TotalLoss from './TotalLoss';
import StolenCheck from './StolenCheck';
import MajorAccident from './MajorAccident';
import Goverment from './Goverment';
import BelowGoverment from './BelowGoverment';
import Summary from './Summary';
import SummaryDown from './SummaryDown';
import logo from './images/logo.png';
import axios from 'axios';
import CarSpecifications from "./components/CarSpecifications";
import MarketValuePopup from "./components/MarketValuePopup";
import Header from "./components/Header";
import { useLocation, useParams } from "react-router-dom";

const App = () => {
	console.log("App")
	const [getdata, setgetdata] = useState({})
	const [scrollY, setScrollY] = useState(0)
	const [vehicaldata, setvehicaldata] = useState({})
	const [getimage,setgetimage]=useState('')
	const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [totalloss, setTotalLoss] = useState(false)

	const location = useLocation()

	const { id } = useParams()

	useEffect(() => {
		function handleScroll() {
			setScrollY(window.scrollY)
		}
		window.addEventListener('scroll', handleScroll)

		window.addEventListener("resize", () => {
			setScreenWidth(window.innerWidth)
		})

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
        if (screenWidth >= 1280) {
			window.scrollTo({
				top: location.state ? location.state.location : 0,
				left: 0,
				behavior: 'smooth'
			})
		}
    }, [location.state])

	// const queryParameters = new URLSearchParams(window.location.search)
	// const id = queryParameters.get("id")

	useEffect(() => {
		axios.post("http://motopsy.com/API/vehicle-registration",{vehicleNumber:id})
		.then(res => {
			setvehicaldata(res.data);
			setgetdata(res.data);
		})
		.catch(err => {
			console.log(err);
		})
	}, [id])

	useEffect(() => {
		if (vehicaldata.model) {
			axios.get(`https://carimagery.com/api.asmx/GetImageUrl?searchTerm=${vehicaldata.model}`)
				.then(res => {
					setgetimage(res.data)
				})
		}
	}, [vehicaldata.model])

	const printdata = () => {
		window.print()
	}

  // function printSelectedData(params) {
  //   const text = 'This is some sample text data';
  //   const searchTerm = 'sample';
  //   const indexOfSearchTerm = text.indexOf(searchTerm);
  //   const extractedText = text.substring(indexOfSearchTerm, indexOfSearchTerm + searchTerm.length);

  //   const printWindow = window.open('', '', 'height=400,width=800');
  //   printWindow.document.write(`<html><body><div>${extractedText}</div></body></html>`);
  //   printWindow.document.close();
  //   printWindow.focus();
  //   printWindow.print();
  //   console.log(params);
  // }

	return (
    	<div className="App">
			<Header vehicleid={id} />
      		<div className='sub-header-paren'>
        		<div className='sub-header'>
          			<div className='sub-header-left-div'>
						<p>Year:2023</p>
					</div>
          			<div className='sub-header-right-div'>
            			<div className='sub-header-vehical-text'>
							<p>Vehicle Registration No</p>
						</div>
            			<div className='sub-header-number-div'>
              				<p>{getdata.regNo}</p>
            			</div>
          			</div>
        		</div>
      		</div>

			{/* Header cards */}
      		<div className='motospy-four-div-main-container'>
        		<div className='four-div-main-container-h'>
          			<img src={logo} style={{width:"inherit",height:"inherit",borderRadius:"inherit"}}/>
        		</div>
        		<div className='printreport' onClick={printdata}>
					<AiFillPrinter className='icon' /> &nbsp;<p>Print</p>
				</div>
        		<div className='four-div-h3-container'>
          			<div className='four-div'>
            			<div className='div-one-top div-one-top-one'>
              				<div className='div-one-half'>
                				<div className='div-one-inside-div'>
                  					<img src={img1} className="img1" />
                  					<h3 className='first-h'>28.5 Cr +</h3>
                  					<p className='first-p'>Vehicle data base</p>
                				</div>
              				</div>
            			</div>
            			<div className='div-one-top div-one-top div-one-top-two'>
              				<div className='div-one-half'>
                				<div className='div-one-inside-div'>
                  					<img src={img2} className="img1 img2" />
									<h3 className='second-h'>10K</h3>
									<p className='second-p'>& growing total<br />loss vehicle list</p>
                				</div>
              				</div>
            			</div>
						<div className='div-one-top div-one-top div-one-top-three'>
							<div className='div-one-half'>
								<div className='div-one-inside-div'>
									<img src={img3} className="img1 img3" />
									<p className='third-p'>Pan India Stolen<br />Vehicle list </p>
								</div>
							</div>
						</div>
						<div className='div-one-top div-one-top div-one-top-four'>
							<div className='div-one-half'>
								<div className='div-one-inside-div'>
									<img src={img4} className="img1 img4" />
									<h3 className='four-h'>8K</h3>
									<p className='four-p'>past & present Indian <br />Cars specs details </p>
								</div>
							</div>
						</div>
          			</div>
        		</div>
      		</div>
			{/* End of header cards */}

			<FirstSection_subMenu 
				setgetdata={setgetdata} 
				vehicledata={vehicaldata} 
				getimage={getimage}
			/>
			<First_section_last 
				vehicledata={Object.keys(vehicaldata).length ? vehicaldata : undefined} 
				totalloss={totalloss}
			/>
			<TotalLoss 
				vehicledata={Object.keys(vehicaldata).length ? vehicaldata : undefined} 
				totalloss={totalloss}
				setTotalLoss={setTotalLoss}
			/>
			<MajorAccident />
			<StolenCheck 
				vehicledata={Object.keys(vehicaldata).length ? vehicaldata : undefined} 
			/>
			<Goverment />
			<BelowGoverment />
			<Summary  
				vehicledata={Object.keys(vehicaldata).length ? vehicaldata : undefined} 
			/>
			<SummaryDown vehicledata={Object.keys(vehicaldata).length ? vehicaldata : undefined} />
    	</div>
	)
}

export default App
