import React, { useEffect, useState } from "react"
import { Base64 } from "js-base64"
import "./style.css"
import { FaLongArrowAltRight } from "react-icons/fa"
import { ImCheckmark, ImCross } from "react-icons/im"
import { AiOutlineMinus } from "react-icons/ai"
import img1 from "../images/Section 4/1.png"
import axios from "axios"

function TotalLoss({ vehicledata, totalloss, setTotalLoss }) {
	useEffect(() => {
		const encoded = Base64.encode(vehicledata && vehicledata.regNo)

		const init = async () => {
			const response = await axios.post("http://74.208.91.110/API/gettotalloss", {
				vehicleNumber: encoded
			})

			setTotalLoss(response.data.message)
		}

		if (encoded) {
			init()
		}
	}, [vehicledata && vehicledata.regNo])

	return (
		<div className="totalLoss-main">
			<div className="top-text">
				{vehicledata ? (
					<p>
						"{vehicledata.vehicleManufacturerName}", {vehicledata.model} {vehicledata.vehicleManufacturingMonthYear && vehicledata.vehicleManufacturingMonthYear.split("/")[1]}
					</p>
				) : undefined}
			</div>
			<div className="sub-text">
				<div className="one first" style={{ filter: "invert(100%)" }}>
					<img src={img1} />
				</div>
				<div className="one second">
					<h1>Total Loss/ Written off Check</h1>
					<p>
						MOTOPSY checks for Vehicles being written off due to
						accident off by Insurance companies and auctioned to
						scrap buyers
					</p>
				</div>
				<div className="one third">
					<FaLongArrowAltRight className="icon2" />
				</div>
				<div className="one four">
					<div className="hide-circle"></div>
					<div className="text-content">
						{totalloss == "false" ? (
							<p>
								Information available is not sufficient to conclude, please have vehicle physically examined
							</p>
						) : (
							<p>
								ALERT! Vehicle reported Blacklist, seek details with respective RTO
							</p>
						)}
					</div>
					<div className={totalloss == "false" ? "main-circle orange" : "main-circle red"}>
						{totalloss == "false" ? <div className="minus-icon">-</div> : <ImCross className="cross-icon" />}
					</div>
					<div className="right"></div>
				</div>
			</div>
		</div>
	);
}

export default TotalLoss

// SkswMkFSOTQyNQ==

