import Header from "../components/Header"
import "./css/VehicleInformation.css"
import Logo from "../images/logo.png"
import { useState, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import api from "../config/backend"
import axios from "axios"

const VehicleInformation = () => {
    const [vehicleData, setVehicleData] = useState([])

    const { id } = useParams()

    const location = useLocation()

    useEffect(() => {
        const init = async () => {
            const cardata = api("caps", "cardata")
            const response = await axios.post(cardata, {
                make: location.state.vehicleMake,
                model: location.state.vehicleModel,
                manufacteryear: location.state.vehicleManufacturingYear
            })
            const data = response.data

            console.log(data)
            
            if (data.length) {
                setVehicleData(data)
            }
        }

        init()
    }, [])

    const cell = (fieldnumber) => {
        if (vehicleData.length === 0) {
            return "N/A"
        }

        const info = vehicleData[0][`FIELD${fieldnumber}`]

        return info === null ? "N/A" : info
    }

    return (
        <div className="_9gba">
            <Header vehicleid={id} />
            <div className="_2stg">
                <div className="heading">
                    <div className="registration-info">
                        <h4>Vehicle Registration No</h4>
                        <span>xxx-xxx-xxx</span>
                    </div>
                </div>
                <div className="hero-image">
                    <img src={Logo} alt="" />
                </div>
                <div className="information-box">
                    <div>
                        <div className="card">
                            <h3>Key Data</h3>
                            <div>
                                <div className="card-item">
                                    <span>Mileage ARAI</span>
                                    <span>{cell(12)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Engine cc</span>
                                    <span>{cell(13)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Transmission type</span>
                                    <span>{cell(14)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Fueltype</span>
                                    <span>{cell(21)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Seatingcapacity</span>
                                    <span>{cell(16)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Drivetrain</span>
                                    <span>{cell(32)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Dimensions & Weight</h3>
                            <div>
                                <div className="card-item">
                                    <span>Length</span>
                                    <span>{cell(41)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Width</span>
                                    <span>{cell(42)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Height</span>
                                    <span>{cell(43)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Wheelbase</span>
                                    <span>{cell(44)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Groundclearance</span>
                                    <span>{cell(45)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Kerbweight</span>
                                    <span>{cell(46)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Capacity</h3>
                            <div>
                                <div className="card-item">
                                    <span>Doors</span>
                                    <span>{cell(47)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Seating capacity</span>
                                    <span>{cell(48)}</span>
                                </div>
                                <div className="card-item">
                                    <span>No of seating rows</span>
                                    <span>{cell(49)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Bootspace</span>
                                    <span>{cell(50)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Fuel tank capacity</span>
                                    <span>{cell(51)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Suspensions, Brakes, Steering & Tyres</h3>
                            <div>
                                <div className="card-item">
                                    <span>Front suspension</span>
                                    <span>{cell(52)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear suspension</span>
                                    <span>{cell(53)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Front brake type</span>
                                    <span>{cell(54)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear brake type</span>
                                    <span>{cell(55)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Minimum turning radius</span>
                                    <span>{cell(56)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Steering type</span>
                                    <span>{cell(57)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Wheels</span>
                                    <span>{cell(58)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Spare wheels</span>
                                    <span>{cell(59)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Front tyres</span>
                                    <span>{cell(60)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear tyres</span>
                                    <span>{cell(61)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Four wheel steering</span>
                                    <span>{cell(62)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Braking performance</span>
                                    <span>{cell(63)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Braking & Traction</h3>
                            <div>
                                <div className="card-item">
                                    <span>Antilock barking system abs</span>
                                    <span>{cell(81)}</span>
                                </div>
                                <div className="card-item">
                                    <span>EBD</span>
                                    <span>{cell(82)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Brake assist BA</span>
                                    <span>{cell(83)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Electronic stability program</span>
                                    <span>{cell(84)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Four wheel drive</span>
                                    <span>{cell(85)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Hill hold control</span>
                                    <span>{cell(86)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Traction control system tc tcs</span>
                                    <span>{cell(87)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Ride height adjustment</span>
                                    <span>{cell(88)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Hill descent control</span>
                                    <span>{cell(89)}</span>
                                </div>
                                <div className="card-item">
                                    <span>LSD</span>
                                    <span>{cell(90)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Differential lock</span>
                                    <span>{cell(91)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Comfort & Convenience</h3>
                            <div>
                                <div className="card-item">
                                    <span>air conditioner</span>
                                    <span>{cell(96)}</span>
                                </div>
                                <div className="card-item">
                                    <span>front ac</span>
                                    <span>{cell(97)}</span>
                                </div>
                                <div className="card-item">
                                    <span>rear ac</span>
                                    <span>{cell(98)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Hl& IR</span>
                                    <span>{cell(99)}</span>
                                </div>
                                <div className="card-item">
                                    <span>keyless start button start</span>
                                    <span>{cell(100)}</span>
                                </div>
                                <div className="card-item">
                                    <span>steering adjustment</span>
                                    <span>{cell(101)}</span>
                                </div>
                                <div className="card-item">
                                    <span>parking sensors</span>
                                    <span>{cell(102)}</span>
                                </div>
                                <div className="card-item">
                                    <span>parking assist</span>
                                    <span>{cell(103)}</span>
                                </div>
                                <div className="card-item">
                                    <span>antiglare mirrors</span>
                                    <span>{cell(104)}</span>
                                </div>
                                <div className="card-item">
                                    <span>vanity mirrors on sunvisors</span>
                                    <span>{cell(105)}</span>
                                </div>
                                <div className="card-item">
                                    <span>heater</span>
                                    <span>{cell(106)}</span>
                                </div>
                                <div className="card-item">
                                    <span>cabin bootaccess</span>
                                    <span>{cell(107)}</span>
                                </div>
                                <div className="card-item">
                                    <span>12v power outlets</span>
                                    <span>{cell(108)}</span>
                                </div>
                                <div className="card-item">
                                    <span>cruise control</span>
                                    <span>{cell(109)}</span>
                                </div>
                                <div className="card-item">
                                    <span>third row ac</span>
                                    <span>{cell(110)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Storage</h3>
                            <div>
                                <div className="card-item">
                                    <span>Cup holders</span>
                                    <span>{cell(142)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Driver armrest storage</span>
                                    <span>{cell(143)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Cooled glove box</span>
                                    <span>{cell(144)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Sunglass holder</span>
                                    <span>{cell(145)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Third row cup holders</span>
                                    <span>{cell(146)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Doors, Windows, Mirrors & Wipers</h3>
                            <div>
                                <div className="card-item">
                                    <span>one touch down</span>
                                    <span>{cell(147)}</span>
                                </div>
                                <div className="card-item">
                                    <span>One touch up</span>
                                    <span>{cell(148)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Power windows</span>
                                    <span>{cell(149)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Adjustable orvm</span>
                                    <span>{cell(150)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Turn indicators on orvm</span>
                                    <span>{cell(151)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear defoggert</span>
                                    <span>{cell(152)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear wiper</span>
                                    <span>{cell(153)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Exterior door handles</span>
                                    <span>{cell(154)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rain sensing wipers</span>
                                    <span>{cell(155)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Interior door handles</span>
                                    <span>{cell(156)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Door pockets</span>
                                    <span>{cell(157)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Side window blinds</span>
                                    <span>{cell(158)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Bootlid opener</span>
                                    <span>{cell(159)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear windshield blind</span>
                                    <span>{cell(160)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Outside rearview mirrors orvms</span>
                                    <span>{cell(161)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Scuff plates</span>
                                    <span>{cell(162)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Lighting</h3>
                            <div>
                                <div className="card-item">
                                    <span>Fog lights</span>
                                    <span>{cell(170)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Daytime running lights</span>
                                    <span>{cell(171)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Headlights</span>
                                    <span>{cell(172)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Automatic head lamps</span>
                                    <span>{cell(173)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Followme home headlamps</span>
                                    <span>{cell(174)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Tail lights</span>
                                    <span>{cell(175)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Cabin lamps</span>
                                    <span>{cell(176)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Headlight height adjuster</span>
                                    <span>{cell(177)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Glove box lamp</span>
                                    <span>{cell(178)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Lights on vanity mirrors</span>
                                    <span>{cell(179)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear reading lamp</span>
                                    <span>{cell(180)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Cornering headlights</span>
                                    <span>{cell(181)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Puddle lamps</span>
                                    <span>{cell(182)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Ambient interior lighting</span>
                                    <span>{cell(183)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Entertainment, Information & Communication</h3>
                            <div>
                                <div className="card-item">
                                    <span>Smart connectivity</span>
                                    <span>{cell(198)}</span>
                                </div>
                                <div className="card-item">
                                    <span>IDMS</span>
                                    <span>{cell(199)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Headunit size</span>
                                    <span>{cell(200)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Display</span>
                                    <span>{cell(201)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Display screen for rear passengers</span>
                                    <span>{cell(202)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Gps navigation system</span>
                                    <span>{cell(203)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Speakers</span>
                                    <span>{cell(204)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Usb compatibility</span>
                                    <span>{cell(205)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Aux compatibility</span>
                                    <span>{cell(206)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Bluetooth compatibility</span>
                                    <span>{cell(207)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Mp3 playback</span>
                                    <span>{cell(208)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Cd player</span>
                                    <span>{cell(209)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Dvd playback</span>
                                    <span>{cell(210)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Am fm radio</span>
                                    <span>{cell(211)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Ipod compatibility</span>
                                    <span>{cell(212)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Internal hard drive</span>
                                    <span>{cell(213)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Steering mounted controls</span>
                                    <span>{cell(214)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Voice command</span>
                                    <span>{cell(215)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Wireless charger</span>
                                    <span>{cell(216)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Gesture control</span>
                                    <span>{cell(217)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear row Seat adjustment</span>
                                    <span>{cell(218)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear row Seat base sliding</span>
                                    <span>{cell(219)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="card">
                            <h3>Engine & Transmission</h3>
                            <div>
                                <div className="card-item">
                                    <span>Engine</span>
                                    <span>{cell(17)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Engine type</span>
                                    <span>{cell(18)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Top speed</span>
                                    <span>{cell(19)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Acceleration 100 kmph</span>
                                    <span>{cell(20)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Fueltype</span>
                                    <span>{cell(21)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Maxpower</span>
                                    <span>{cell(22)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Maxpowerrpm</span>
                                    <span>{cell(23)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Maxtorque</span>
                                    <span>{cell(24)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Maxtorquerpm</span>
                                    <span>{cell(25)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Performance on alternate fuel</span>
                                    <span>{cell(26)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Maxengine performance</span>
                                    <span>{cell(27)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Maxmotor performance</span>
                                    <span>{cell(28)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Mileage arai</span>
                                    <span>{cell(29)}</span>
                                </div>
                                <div className="card-item">
                                    <span>PCPm</span>
                                    <span>{cell(30)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Driving range</span>
                                    <span>{cell(31)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Drivetrain</span>
                                    <span>{cell(32)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Transmission</span>
                                    <span>{cell(33)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Emission standard</span>
                                    <span>{cell(34)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Turbocharger supercharger</span>
                                    <span>{cell(35)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Battery</span>
                                    <span>{cell(36)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Battery charging</span>
                                    <span>{cell(37)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Electric motor</span>
                                    <span>{cell(38)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Others</span>
                                    <span>{cell(39)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Alternate fuel</span>
                                    <span>{cell(40)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Safety</h3>
                            <div>
                                <div className="card-item">
                                    <span>Overspeed warning</span>
                                    <span>{cell(64)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Lane departure warning</span>
                                    <span>{cell(65)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Emergency brake light flashing</span>
                                    <span>{cell(66)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Forward collision warning fcw</span>
                                    <span>{cell(67)}</span>
                                </div>
                                <div className="card-item">
                                    <span>AEB</span>
                                    <span>{cell(68)}</span>
                                </div>
                                <div className="card-item">
                                    <span>High beam assist</span>
                                    <span>{cell(69)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Ncap rating</span>
                                    <span>{cell(70)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Blind spot detection</span>
                                    <span>{cell(71)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Puncture repair kit</span>
                                    <span>{cell(72)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear cross traffic assist</span>
                                    <span>{cell(73)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Airbags</span>
                                    <span>{cell(74)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Middle rear three point seatbelt</span>
                                    <span>{cell(75)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Middle rear headrest</span>
                                    <span>{cell(76)}</span>
                                </div>
                                <div className="card-item">
                                    <span>TPMS</span>
                                    <span>{cell(77)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Child seat anchor points</span>
                                    <span>{cell(78)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Seatbelt warning</span>
                                    <span>{cell(79)}</span>
                                </div>
                                <div className="card-item">
                                    <span>lane departure prevention</span>
                                    <span>{cell(80)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Locks & Security</h3>
                            <div>
                                <div className="card-item">
                                    <span>Engineimmobilizer</span>
                                    <span>{cell(92)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Central locking</span>
                                    <span>{cell(93)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Speed sensing doorlock</span>
                                    <span>{cell(94)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Child safety lock</span>
                                    <span>{cell(95)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Telematics</h3>
                            <div>
                                <div className="card-item">
                                    <span>RCL flashing & honking via app</span>
                                    <span>{cell(121)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Geofence</span>
                                    <span>{cell(122)}</span>
                                </div>
                                <div className="card-item">
                                    <span>RSr open close via app</span>
                                    <span>{cell(123)}</span>
                                </div>
                                <div className="card-item">
                                    <span>OTA</span>
                                    <span>{cell(124)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Check vehicle status via app</span>
                                    <span>{cell(125)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Remote car lock unlock via app</span>
                                    <span>{cell(126)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Emergency call</span>
                                    <span>{cell(127)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Remote ac on off via app</span>
                                    <span>{cell(128)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Alexa compatibility</span>
                                    <span>{cell(129)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Seats & Upholstery</h3>
                            <div>
                                <div className="card-item">
                                    <span>driver seat adjustment</span>
                                    <span>{cell(121)}</span>
                                </div>
                                <div className="card-item">
                                    <span>front passenger seat adjustment</span>
                                    <span>{cell(122)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear row seat adjustment</span>
                                    <span>{cell(123)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Third row seat adjustment</span>
                                    <span>{cell(124)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Seat upholstery</span>
                                    <span>{cell(125)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Leather wrapped steering wheel</span>
                                    <span>{cell(126)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Leather wrapped gear knob</span>
                                    <span>{cell(127)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Driver armrest</span>
                                    <span>{cell(128)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear passenger seats type</span>
                                    <span>{cell(129)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Third row seats type</span>
                                    <span>{cell(130)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Ventilated seats</span>
                                    <span>{cell(131)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Ventilated seat type</span>
                                    <span>{cell(132)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Interiors</span>
                                    <span>{cell(133)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Interior colours</span>
                                    <span>{cell(134)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rear armrest</span>
                                    <span>{cell(135)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Folding rear seat</span>
                                    <span>{cell(136)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Split rear seat</span>
                                    <span>{cell(137)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Split third row seat</span>
                                    <span>{cell(138)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Front seat pocket</span>
                                    <span>{cell(139)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Headrests</span>
                                    <span>{cell(140)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Fourth row seat adjustment</span>
                                    <span>{cell(141)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Exterior</h3>
                            <div>
                                <div className="card-item">
                                    <span>Sunroof moonroof</span>
                                    <span>{cell(163)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Roof rails</span>
                                    <span>{cell(164)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Roof mounted antenna</span>
                                    <span>{cell(165)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Body coloured bumpers</span>
                                    <span>{cell(166)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Chrome finish exhaust pipe</span>
                                    <span>{cell(167)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Body kit</span>
                                    <span>{cell(168)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Rub strips</span>
                                    <span>{cell(169)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Instrumentation</h3>
                            <div>
                                <div className="card-item">
                                    <span>Instrument cluster</span>
                                    <span>{cell(184)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Trip meter</span>
                                    <span>{cell(185)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Average fuel consumption</span>
                                    <span>{cell(186)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Average speed</span>
                                    <span>{cell(187)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Distance to empty</span>
                                    <span>{cell(188)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Clock</span>
                                    <span>{cell(189)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Low fuel level warning</span>
                                    <span>{cell(190)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Door ajar warning</span>
                                    <span>{cell(191)}</span>
                                </div>
                                <div className="card-item">
                                    <span>ACB</span>
                                    <span>{cell(192)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Gear indicator</span>
                                    <span>{cell(193)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Shift indicator</span>
                                    <span>{cell(194)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Heads up display hud</span>
                                    <span>{cell(195)}</span>
                                </div>
                                <div className="card-item">
                                    <span>Tachometer</span>
                                    <span>{cell(196)}</span>
                                </div>
                                <div className="card-item">
                                    <span>IC</span>
                                    <span>{cell(197)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Manufacturer Warranty</h3>
                            <div>
                                <div className="card-item">
                                    <span>warranty in years</span>
                                    <span>{cell(220)}</span>
                                </div>
                                <div className="card-item">
                                    <span>warranty in kms</span>
                                    <span>{cell(221)}</span>
                                </div>
                                <div className="card-item">
                                    <span>battery warranty in years</span>
                                    <span>{cell(222)}</span>
                                </div>
                                <div className="card-item">
                                    <span>battery warranty in kms</span>
                                    <span>{cell(223)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Colors</h3>
                            <div>
                                <div className="card-item">
                                    <span>colorname</span>
                                    <span>{cell(224)}</span>
                                </div>
                                <div className="card-item">
                                    <span>color rgb</span>
                                    <span className="content-overflow">{cell(225)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h3>Price breakdown</h3>
                            <div>
                                <div className="card-item">
                                    <span>ex showroom price</span>
                                    <span>{cell(9)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleInformation
